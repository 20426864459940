/* global Blob */

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.factory:DataReconciliation
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .factory('DataReconciliation', DataReconciliation);

  function DataReconciliation(wbResource) {
    var url = 'data-reconciliations/';

    return wbResource('dataReconciliation', url, {}, {
      query: {
        method: 'GET',
        cache: false,
        isArray: true
      },
      get: {
        method: 'GET',
        cache: false,
        isArray: true
      },
      delete: {
        method: 'POST',
        cache: false,
        url: url + 'delete',
        isArray: false
      },
      requestEmailNotification: {
        method: 'POST',
        cache: false,
        url: url + 'requestEmailNotification',
        isArray: false
      },
      validateDataReconciliations: {
        method: 'POST',
        url: url + 'validate'
      },
      createDataReconciliations: {
        method: 'POST',
        url: 'users/:userId/companies/:companyId/' + url,
        params: {
          userId: '@userId',
          companyId: '@companyId'
        }
      }
    });
  }
}());
